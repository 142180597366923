@import "./assets/index.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

.megaMenu {
  display: flex;
  justify-content: space-between;
}
.megaMenu-sub1 {
  display: "flex";
  width: 100vh;
  padding: 10px;
  border-right: 2px solid black;
  /* background-color: black; */
}
.megaMenu-sub2 {
  display: "flex";
  width: 100vh;
  padding: 10px;
  border-right: 2px solid black;
  /* background-color: aqua; */
}
.megaMenu-sub3 {
  display: "flex";
  padding: 10px;
  width: 100vh;
  border-right: 2px solid black;
  /* background-color: blue; */
}
.megaMenu-sub4 {
  display: "flex";
  padding: 10px;
  width: 100vh;
  border-right: 2px solid black;
  /* background-color: blue; */
}
.megaMenu-sub5 {
  display: "flex";
  padding: 10px;
  width: 100vh;
  border-right: 2px solid black;
  /* background-color: blue; */
}
.hiidenText {
  background-color: #e9ecef !important;
  opacity: 1;
}

.skeletonLoading {
  background-color: #e0e0e0;
  border-radius: 4px;
  display: inline-block;
  height: 20px;
  position: relative;
  overflow: hidden;
  animation: shimmerLoading 1.5s infinite;
}

.skeletonLoading-text {
  width: 100px;
  height: 14px;
}

@keyframes shimmerLoading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
